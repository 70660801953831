import { useSysState } from '../context/SysStateContext';
import RealmCard from '../pageparts/RealmCard';
import { Row, Col, Container } from "react-bootstrap";
import { useEffect, useState } from 'react';
import useDataFetch from "../hooks/useDataFetch";
import { useNavigate } from "react-router-dom";

export default function SwitchRealm() {
  const sysState = useSysState().state; // Remember that the state data is within state property!
  const sysStateDispatch = useSysState().dispatch;
  const [options, setOptions] = useState({ url: "", query: null });
  const { response: theResponse, error: hasError, loading: isLoading } = useDataFetch( options);
  const centerStyle={ textAlign: 'center'}
  const navigate = useNavigate(); 

  useEffect(() => {
    if (theResponse && theResponse.payload && theResponse.payload.type && theResponse.payload.type==="switch") {
      const res = theResponse.payload;
      sysState.realm = res.realmid;
      sysState.realmname = sysState.realms[res.realmid].name;
      sysState.lvl = res.userlvl;
      const cmdObj = {type: 'switch', newState: sysState};
      sysStateDispatch(cmdObj);
      navigate('/booking');
    }
  },[theResponse,navigate,sysStateDispatch,sysState]);

  const selectRealm = (e,id, remember) => {
    e.preventDefault();
    setOptions({
      url: process.env.REACT_APP_APIURL+"login/",
      method: "PATCH",
      onDone: ()=>{},
      body: {
        cmd: 'newrealm',
        realm: id,
        store: remember
      }
    });
  }
  if (isLoading) {
    return(<div>Sætter ny gruppe</div>);
  }

  if (hasError) {
    return(<div>Der skete en fejl under gruppeskiftet.</div>);
  }

  return (
    <Container>
    <h3 style={centerStyle}>Vælg hvilken gruppe du vil benytte i systemet</h3>
    <div style={centerStyle}>Du er medlem af flere grupper i systemt, og kan derfor skifte mellem dem her.<br/>
    Hold øje med navnet i øverste venstre hjørne, hvor du kan se den "aktive" gruppe.</div>
    <Row className="mt-2 mb-3 fluid justify-content-md-center">
      {Object.getOwnPropertyNames(sysState.realms).map(
        (key, i) => <Col  key={i} xs={12} sm={6} md={6} lg={3} className="col-lg-4 col-md-3 col-sm-2"><RealmCard id={key[0]} active={key[0]===sysState.realm} name={sysState.realms[key].name} selectRealm={selectRealm} /></Col>
      )}
    </Row>
    <Row><Col  style={centerStyle}>* "Husk" betyder, at systemet starter med denne gruppe næste gang du logger på.</Col></Row>
    </Container>
  );
}