import { createContext, useState, useContext, useReducer } from 'react'
 
const SysStateContext = createContext();
 
const defaultSysState = {
        auth: false,
        lvl: 10,
        path: '',
        init: 'Not set',
        name: '',
        realm: 0,
        realmname: '',
        realms: [],
        realmUsers: {},
        warnings: []
    }
 
function sysStateReducer(state, action) {
    switch (action.type) {
      case 'new': {
        return {...state, init: action.init}
      }
      case 'logoff': {
        return {...state, ...defaultSysState}
      }
      case 'login': {
        return {...state, ...action.loginvalues }
      }
      case 'switch': {
        return {...action.newState}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
}
 
function SysStateProvider({children, settings}) {
    useState(
        settings || defaultSysState
      );
 
    const [state, dispatch] = useReducer(sysStateReducer, settings || defaultSysState)
    const contextval = {state, dispatch}
    return <SysStateContext.Provider value={contextval}>{children}</SysStateContext.Provider>
}
 
function useSysState() {
    const context = useContext(SysStateContext)
    if (context === undefined) {
      throw new Error('useSysState must be used within a SysStateProvider')
    }
    return context
}
 
export { SysStateProvider, useSysState}