import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function AlertDialog(props) {

  const handleCancel = () => { 
    props.displaySet(false);
  }

  const cancelText = props.hasOwnProperty('cancelText') ? props.cancelText : 'Annullér';
  const cancelType = props.hasOwnProperty('cancelType') ? props.cancelType : 'secondary';
  const okText = props.hasOwnProperty('okText') ? props.okText : 'Ok';
  const okType = props.hasOwnProperty('okType') ? props.okType : 'danger';
  
  return (
    <Modal
      show={props.show}
      onCancel={handleCancel}
      backdrop={props.hasOwnProperty('backdrop') ? props.backdrop : true }
      keyboard={props.hasOwnProperty('keyboard') ? props.keyboard : true }
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={cancelType} onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button variant={okType}  onClick={props.handleAccept}>{okText}</Button>
      </Modal.Footer>
    </Modal>
  );
}
