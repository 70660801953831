import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import useDataFetch from "../hooks/useDataFetch";
import { useState } from 'react';
import { Row, Col } from "react-bootstrap";

export default function ChangePasswordForm() {
  const [showAccept, setShowAccept] = useState(false);
  const [pwError, setPwError] = useState('');
  const [password2, setPassword2] = useState('');
  const [password, setPassword] = useState('');
  const [options, setOptions] = useState({ url: "", query: null });
  const { response: theResponse, error: hasError, loading: isLoading } = useDataFetch( options);

   const passwordSet = () => {
     if (!hasError) {
      setPassword('');
      setPassword2('');
      setShowAccept(true);
     }
  }

  const sendPassword = e => {
    e.preventDefault();
    if (password !== password2) {
      setPwError('De to passwords er ikke ens!');
      return;
    }
    if (password.length < 4) {
      setPwError('Password er ikke på mindst 4 tegn');
      return;
    }
    setOptions({
      url: process.env.REACT_APP_APIURL+"login/",
      method: "PATCH",
      onDone: passwordSet,
      body: {
        cmd: 'newpw',
        pass: password
      }
    });
  };

  return (
    <Row className="justify-content-md-center mt-2">
    <Col md="12">
    <Form className="border HF-light-border rounded-2 py-4 px-4"  onSubmit={sendPassword}>
    <div className="mb-2"><strong>Sæt nyt password:</strong></div>
    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control 
        type="password"
        placeholder="Password - Mindst 4 tegn!"
        value={password}
        onChange={event => {setShowAccept(false); setPwError(''); setPassword(event.target.value)}}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword2">
      <Form.Label>gentag password</Form.Label>
      <Form.Control 
        type="password"
        placeholder="Gentag password så evt. trykfejl opdages"
        value={password2}
        onChange={event => {setShowAccept(false); setPwError(''); setPassword2(event.target.value)}}/>
    </Form.Group>

    <Button variant="primary" type="submit" disabled={password.length===0}>
      {!isLoading && "Skift password"}
      {isLoading && "Skifter password"}
    </Button>
    {!isLoading && hasError && theResponse.payload && 
      <Alert className="mt-2" variant="warning">
        <p>{theResponse.payload.error}</p>
      </Alert>}
    {showAccept && 
      <Alert className="mt-2" variant="success">
      <p>Dit password er opdateret!<br/>Husk det ved næste login.</p>
      </Alert>}
    {pwError !== '' && 
      <Alert className="mt-2" variant="danger">
      <p>
        {pwError}
      </p>
      </Alert>}
    </Form>
    </Col>
    </Row>
  );
}