import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';
import BookingList from "../pages/BookingList.js";

const ActiveBookingsContext = createContext();

export const useActiveBookings = () => useContext(ActiveBookingsContext);

export default function ActiveBookingsProvider({children})  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"booking/all/mine", query: { page: 1, pageSize: 1000, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);
  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter din bookingliste...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <ActiveBookingsContext.Provider value={{response, error, loading, reload}}>
      <BookingList />
    </ActiveBookingsContext.Provider>
  );
}