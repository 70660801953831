import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import useDataFetch from "../hooks/useDataFetch";
import { useSysState } from '../context/SysStateContext';
import { useEffect, useState } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [options, setOptions] = useState({ url: "", query: null });
  const { response: theResponse, error: hasError, loading: isLoading } = useDataFetch( options);
  const sysStateDispatch = useSysState().dispatch;

  let navigate = useNavigate(); 
  const routeChange = (realm) =>{ 
    if (realm === 0) {
      navigate('/noaccess');
    } else {
      navigate('/booking');
    }
  }

  useEffect(() => {
    const logon = (payload) => {
      sysStateDispatch(payload);
    }

    if (theResponse && theResponse.payload && theResponse.payload.type && theResponse.payload.type==="login") {
      logon(theResponse.payload);
      if (theResponse.payload.loginvalues.auth) {
        routeChange(theResponse.payload.loginvalues.realm);
      }
    }
  });
  
  const loginReturn = () => {
  }

  const doLogin = e => {
    e.preventDefault();
    setOptions({
      url: process.env.REACT_APP_APIURL+"login/",
      method: "POST",
      onDone: loginReturn,
      body: {
        name: email,
        pass: password
      }
    });
  };

  return (
    <Container>
    <Row className="justify-content-md-center">
    <Col md="auto">
    <Form className="border HF-light-border rounded-2 py-4 px-4"  onSubmit={doLogin}>
    <div className="mb-2"><strong>Login med email og password:</strong></div>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Email addresse</Form.Label>
      <Form.Control 
        type="email"
        placeholder="Din email adresse"
        value={email}
        onChange={event => setEmail(event.target.value)}
        htmlSize="50"/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control 
        type="password"
        placeholder="Password"
        value={password}
        onChange={event => setPassword(event.target.value)}/>
    </Form.Group>

    <Button variant="primary" type="submit">
      {!isLoading && "Login"}
      {isLoading && "Validerer login"}
    </Button>
    {!isLoading && hasError && theResponse.payload && 
      <Alert className="mt-2" variant="warning">
        <p>{theResponse.payload.error}</p>
      </Alert>}
    {/* }
    <hr/>
    <div className="mb-2"><strong>Login med din brugerkonto fra:</strong><br/><span className="text-muted small">Klik på et af logoerne</span></div>
    <div className="vstack gap-3">
      <div className="text-center"><a href="#"><img src={process.env.PUBLIC_URL + "/gfx/googlelogo_color_92x30dp.png"} height={50} alt="Google logo" className="btn btn-link"></img></a></div>
      <div className="text-center"><a href="#"><img src={process.env.PUBLIC_URL + "/gfx/f_logo_RGB-Blue_58.png"} height={58} alt="Facebook logo" className="btn btn-link"></img></a></div>
    </div>
    {*/}
    </Form>
    </Col>
    </Row>
    </Container>
  );
}