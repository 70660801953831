import { Container } from "react-bootstrap";
import Header from "./header/Header";

function BasePage(props) {
  return(
    <>
    <Header />
    <Container fluid>
    {props.subelement}
    </Container>
    </>
  );
}

export default BasePage;