import { useItemRelatives } from '../providers/Items2CategoryProvider.js';
import ItemSelectCard from "./ItemSelectCard.js";

export default function ItemSelectCards(props)  {
  const { response } = useItemRelatives();

  const itemList = response.payload.map(item => 
    <ItemSelectCard item={item}  key={item.id} selectItem={props.selectItem}/>
  );
  return (
    <div className="row mt-2" style={{margin: "0.5em"}}><div>Vælg hvilken ting du vil booke ...</div>{itemList}</div>
  );
}