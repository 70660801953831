import { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";

export const CustomDropdown = (props) => (
  <Form.Group className="mb-3" controlId="formRule">
    <Form.Label>{props.label}</Form.Label>
    <Form.Select 
      name={props.name}
      onChange={e => {
        props.onChange(e.target.value);
      }}
      value={props.selected}
    >
      <option defaultValue key={0}>Vælg en mulighed...</option>
      {props.options.map((item, index) => (
        <option key={index} value={item.id} >
          {item.name}
        </option>
      ))}
    </Form.Select>
  </Form.Group>
)

export default function CustomListDropDown(props) {
  const [selectCol, setSelectCol] = useState([]);
  const [selectVal, setSelectVal] = useState(0);
  
  const [options, setOptions] = useState({ url: "", query: null });
  const {error: hasError, loading: isLoading } = useDataFetch(options);

  
  useEffect(() => {
    const onFetchedOptions = (response) => {
      setSelectCol(response.payload);
      setSelectVal(props.selected);
    };

    setOptions({
      url: process.env.REACT_APP_APIURL+props.repository,
      method: "GET",
      onDone: onFetchedOptions
    });
  },[props.repository,props.selected]);
  
  const doSelect = (value) => {
    setSelectVal(value);
    props.onChange(value);
  }

  if (hasError) {
    return ('Der skete en fejl. Valgmuligheder kunne ikke hentes.');
  }

  return (
        <>
        {isLoading ? <><ClockLoader/> ... Henter valgmuligheder...</> :
        <CustomDropdown
          name={props.name}
          options={selectCol}
          selected={selectVal}
          onChange={doSelect}
          label={props.label}
        />}
        </>
    );
}