import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';
import Bookings from "../pages/Bookings";

const BookingsContext = createContext();

export const useBookings = () => useContext(BookingsContext);

export default function BookingsProvider()  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"setup/bookings", query: { page: 1, pageSize: 10, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);

  const [selectedItem, setSelectedItem] = useState({key: 0, id: 0, name: "", descr: ""});

  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter fremtidige bookinger...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <BookingsContext.Provider value={{selectedItem, setSelectedItem, response, error, loading, reload}}>
      <Bookings />
    </BookingsContext.Provider>
  );
}