import Card from "react-bootstrap/Card";

export default function FAQ() {
  const iconStyle = {
    height: 15,
    top: '-3px',
    position: 'relative'
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Svar på almindelige spørgsmål</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Finder du ikke svar, så skriv til Robert.K.Madsen@gmail.com</Card.Subtitle>
        <Card.Text>
        <blockquote className="blockquote mb-3"><p>Alle tider er "grået ud" med streg igennem?</p>
      <footer className="blockquote-footer">
        Der vil ofte være sat regler op for, hvor meget du kan book fremadrettet.<br/>
        Derfor vil du se en advarsel med "<i>Du kan ikke have flere fremtidige bookinger af denne ting.</i>" eller "<i>Du kan ikke have flere fremtidige bookinger i denne kategori.</i>".<br/>
        Du <i>har</i> altså allerede så mange fremtidige bookinger, som der er tilladt.<br/>
        Du kan klikke på "Mine Bookinger" ved siden af Booking knappen for at se hvornår du har booket (og evt. fjerne nogle af disse).<br/>
        Når en bookingtid passeres, så tæller den ikke længere med, og du kan foretage en ny booking!<br/>
        <i>Ingen mulige bookinger <b>kan</b> også skyldes at der er blevet lukket for bookinger af tingen.</i>
      </footer>
    </blockquote>
    <blockquote className="blockquote mb-3"><p>Jeg er med i en anden gruppe, hvordan skifter jeg?</p>
      <footer className="blockquote-footer">
        Hvis du er med i an anden gruppe på helleFor, kan du skifte til denne ved at klikke på gruppenavnet i toppen.<br/>
        Dette er kun muligt, hvis du <i>er</i> medlem af flere grupper, og så vil du se <img src={process.env.PUBLIC_URL + "/gfx/replace.svg"} style={iconStyle} alt="Skift gruppe"/> ved siden af gruppenavnet.<br/>
        Når du klikker får du mulighed til at skifte til en anden gruppe, og også mulighed for at gøre denne gruppe til den gruppe som du starter i ved logon.
      </footer>
    </blockquote>
    <blockquote className="blockquote mb-3"><p>Kan jeg se, hvem der har booket ting?</p>
      <footer className="blockquote-footer">
        <i>Hvis</i> gruppen er sat op til at bookinger er "offentlige" for andre gruppemedlemmer, så ja!<br/>
        Hvis gruppen er sat til dette, vil der ved siden af hver booket tidspunkt være et ikon <img src={process.env.PUBLIC_URL + "/gfx/user-flat.svg"} style={iconStyle} alt="Vis bruger"/>.<br/>
        Klikker du på denne kommer der en lille popup med navnet på den, som har den pågældende tid.
      </footer>
    </blockquote>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
