import { useState, useEffect } from "react"

const queryString = params =>
  Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&")

const createUrl = (url, queryOptions) => {
  if (queryOptions != null) {
    return url + "?" + queryString(queryOptions);
  } else {
    return url;
  }
}

const useDataFetch = (options = { url: "", body: {}, query: null }) => {
  const [data, setData] = useState({
    response: null,
    error: false,
    loading: true
  });

  useEffect(() => {
    if (options.url === "") {
      setData({
        response: null,
        error: false,
        loading: false
      });
      return;
    }
    const abortController = new AbortController();

    setData({ response: {}, error: null, loading: true });
    fetch( createUrl(options.url, options.query), {
      method: options.method || "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(options.body),
      signal: abortController.signal
    })
      .then(async response => {
        const data = await response.json();
        setData({
          response: data,
          error: !response.ok,
          loading: false
        });
        if (options.onDone) {
          options.onDone(data);
        }
      })
      .catch(error => {
        //fetch throws an error only on network failure or if anything prevented the request from completing
        if (!abortController.signal.aborted) { // if not aborted due to unmount...
          setData({
            response: { status: "network_failure" },
            error: true,
            loading: false
          })
        }
      });
    return function cleanup() {
      abortController.abort();
    };
     
  }, [options]);

  return data;
}

export default useDataFetch;
