import { useBookings } from '../providers/BookingsProvider';
import { useState } from 'react';
import { Button } from "react-bootstrap";
import Booking from '../pageparts/Booking';
import useDataFetch from "../hooks/useDataFetch";
import AlertDialog from "../forms/AlertDialog.js";

export default function Bookings()  {
  const { response, reload } = useBookings();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedBooking, setselectedBooking] = useState({ i:0, s: 0, d: ''});
  const [options, setOptions] = useState({ url: "", query: null });
  const { error: hasError } = useDataFetch(options);
  
  const nameCatItem = ((a,b)=>{
    // Username
    if (response.payload.users[a.u].toLocaleLowerCase() < response.payload.users[b.u].toLocaleLowerCase()) return -1;
    if (response.payload.users[a.u].toLocaleLowerCase() > response.payload.users[b.u].toLocaleLowerCase()) return 1;
    // Cat name
    if (response.payload.cats[a.c].toLocaleLowerCase() < response.payload.cats[b.c].toLocaleLowerCase()) return -1;
    if (response.payload.cats[a.c].toLocaleLowerCase() > response.payload.cats[b.c].toLocaleLowerCase()) return 1;
    // Item name
    if (response.payload.items[a.i].toLocaleLowerCase() < response.payload.items[b.i].toLocaleLowerCase()) return -1;
    if (response.payload.items[a.i].toLocaleLowerCase() > response.payload.items[b.i].toLocaleLowerCase()) return 1;
    // Date
    if (a.d < b.d) return -1;
    if (a.d > b.d) return 1;
    // Slot
    if (a.s < b.s) return -1;
    if (a.s > b.s) return 1;
    return 0
  });
  
  const catItem = ((a,b)=>{
    // Cat name
    if (response.payload.cats[a.c].toLocaleLowerCase() < response.payload.cats[b.c].toLocaleLowerCase()) return -1;
    if (response.payload.cats[a.c].toLocaleLowerCase() > response.payload.cats[b.c].toLocaleLowerCase()) return 1;
    // Item name
    if (response.payload.items[a.i].toLocaleLowerCase() < response.payload.items[b.i].toLocaleLowerCase()) return -1;
    if (response.payload.items[a.i].toLocaleLowerCase() > response.payload.items[b.i].toLocaleLowerCase()) return 1;
    // Date
    if (a.d < b.d) return -1;
    if (a.d > b.d) return 1;
    // Slot
    if (a.s < b.s) return -1;
    if (a.s > b.s) return 1;
    return 0
  });

  const deleteBooking = (booking) => {
    setselectedBooking(booking);
    setShowDeleteAlert(true);
  }

  const doDeleteBooking = () => {
    setShowDeleteAlert(false);
    setOptions({
      url: process.env.REACT_APP_APIURL+"setup/bookings",
      method: "DELETE",
      onDone: reload,
      body: {
        itemId: selectedBooking.i,
        slot: parseInt(selectedBooking.s),
        theDate: selectedBooking.d,
        user: selectedBooking.u
      }
    });
  }

  const deleteProps = { 
    displaySet: setShowDeleteAlert, 
    handleAccept: doDeleteBooking,
    title: "Vil du slette denne booking?",
    okText: "Slet den"
  }

  const [sortFunc, setSortFunc] = useState(() => (a,b) => nameCatItem(a,b)); // To set a state function you need to pass it like this, because state can be set by setTheState(prevState => prevState * 2)
  const dateDisplayOptions = { weekday: 'long', day: 'numeric', month: 'long' };
  
  const bookings = response.payload.bookings;
  bookings.sort(sortFunc);

  const bookingList = bookings.map((booking,i) => {
    const day = new Date(booking.d);
    booking.dStr = day.toLocaleDateString('da-DK',dateDisplayOptions);
    booking.idx = i;
    booking.deleteFunc = deleteBooking;
    return(
      <Booking p={response.payload} booking={booking}/>
    );
  });

  return(
    <>
    <div className='mb-2'>Fremtidige bookinger: 
      <Button variant="secondary" className="ms-3" size="sm" onClick={() => setSortFunc(() => (a,b) => catItem(a,b))}>Sortér efter Kategori/Ting</Button>
      <Button variant="secondary" className="ms-3" size="sm" onClick={() => setSortFunc(() => (a,b) => nameCatItem(a,b))}>Sortér efter Bruger/Kategori/Ting</Button>
    </div>
    {bookingList}
    <AlertDialog {...deleteProps} show={showDeleteAlert}>
      {response.payload.users[selectedBooking.u]}, {response.payload.cats[selectedBooking.c]}, {response.payload.items[selectedBooking.i]}<br/>
      {selectedBooking.dStr}, kl: {String(selectedBooking.s).padStart(4,'0').substring(0,2)+':'+String(selectedBooking.s).padStart(4,'0').substring(2,4)}.
    </AlertDialog>    
    </>
  );
}