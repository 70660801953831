import { Form, Button, Alert } from "react-bootstrap";
import { useState } from 'react';
import useDataFetch from "../hooks/useDataFetch";
import AlertDialog from "../forms/AlertDialog.js";

export default function Category(props)  {
  const [id] = useState(props.id);
  const [name, setName] = useState(props.name);
  const [descr, setDescr] = useState(props.descr);
  const [maxSame, setMaxSame] = useState(props.maxsame);
  const [maxConcurrent, setMaxConcurrent] = useState(props.maxconcurrent);
  const [options, setOptions] = useState({ url: "", query: null });
  const { error: hasError, loading: isLoading } = useDataFetch(options);
  const [lastResponse, setLastResponse] = useState({});
  // For delete button
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const doDelete = e => {
    setShowDeleteAlert(false);
    setOptions({
      url: process.env.REACT_APP_APIURL+"categories/"+id,
      method: "DELETE",
      onDone: onCatDelete,
      body: {
        id: id
      }
    });
  }
  
  const deleteProps = { 
      displaySet: setShowDeleteAlert, 
      handleAccept: doDelete,
      title: "Vil du slette denne kategori?",
      okText: "Slet kategorien"
  }

  const onCatUpdate = (response) => {
    setLastResponse(response);
    if (response.status && response.status < 300) {
      props.topUpdate((oldState) => { return {...oldState, selected: response.payload.id} });
      props.catUpdate((oldCat) => { 
        const newCatState = {...oldCat, name: name, descr: descr, id: response.payload.id, maxsame: response.payload.maxsame, maxconcurrent: response.payload.maxconcurrent};
        return newCatState;
      });
    }
  }

  const startDelete = e => {
    setShowDeleteAlert(true);
  }

  const onCatDelete = (response) => {
    setLastResponse(response);
    if (response.status && response.status < 300) {
      props.topUpdate((oldState) => { return {...oldState, selected: -1} });
      props.catUpdate({key: -1, id: -1, name: ""});
    }
  }

  const submit = e => {
    e.preventDefault();
    setOptions({
      url: process.env.REACT_APP_APIURL+"categories/"+(id > 0 ? id : ''),
      method: (id > 0 ? "PUT" : "POST"),
      onDone: onCatUpdate,
      body: {
        id: id,
        name: name,
        descr: descr,
        maxsame: maxSame,
        maxconcurrent: maxConcurrent,
      }
    });
  };

  if (props.id === 0) {
    return(<div>Vælg en kategori</div>);
  }

  return(
    <Form onSubmit={submit}>
    <input type="hidden" name="catId" id="catId" value={id} readOnly/>
    <Form.Group className="mb-3" controlId="formCatName">
      <Form.Label>Navn på kategori</Form.Label>
      <Form.Control 
        type="text" 
        placeholder="Navn på kategorien" 
        value={name} 
        onChange={event => setName(event.target.value)}
        required/>
      <Form.Text className="text-muted">
        Navnet skal være mindst 4 tegn langt.
      </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formCatDescr">
      <Form.Label>Kort beskrivelse</Form.Label>
      <Form.Control 
        as="textarea" rows={3} 
        placeholder="Evt. en beskrivelse af kategorien" 
        onChange={event => setDescr(event.target.value)}
        value={descr}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formCatMaxSame">
      <Form.Label>{maxSame < 10 ? "Maximum "+maxSame+" samtidige bookings" : "Intet maximum for samtidige bookinger"} af én ting fra kategorien</Form.Label>
      <Form.Range 
        rows={3} 
        min={1} max={10}
        onChange={event => setMaxSame(event.target.value)}
        value={maxSame}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formCatMaxConcurrent">
      <Form.Label>{maxConcurrent < 10 ? "Maximum "+maxConcurrent+" samtidige bookings" : "Intet maximum for samtidige bookinger"} fra <i>hele</i> kategorien</Form.Label>
      <Form.Range 
        rows={3} 
        min={1} max={10}
        onChange={event => setMaxConcurrent(event.target.value)}
        value={maxConcurrent}/>
    </Form.Group>
    <Button variant="primary" type="submit" disabled={isLoading}>
      { id > 0 && !isLoading && "Opdatér kategori"}
      { id === -1 && !isLoading && "Opret kategori"}
      { isLoading && "Opdaterer..."}
    </Button>
    { id > 0 && !isLoading &&
      <>
      &nbsp;
      <Button variant="warning" onClick={() => { startDelete(id)}}>Slet Kategori</Button>
      <AlertDialog {...deleteProps} show={showDeleteAlert}>
        <i>Bemærk:</i> Hvis der er tilknyttet ting til kategorien, så kan den ikke slettes.
      </AlertDialog>
       </>
    }
    {(hasError || (lastResponse.status && lastResponse.status >= 300)) &&
      <Alert className="mt-2" variant="warning">
        <p>{lastResponse.payload.error}</p>
      </Alert>}
  </Form>
  );
}