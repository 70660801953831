import './App.css';
import BrowserNavigation from './header/BrowserNavigation';
import { SysStateProvider } from './context/SysStateContext';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <SysStateProvider>
      <div className="App">
        <BrowserNavigation path="/" />
      </div>
    </SysStateProvider>
  );
}

export default App;
