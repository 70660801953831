import { useCategoryRelatives } from '../providers/Categories2ItemProvider.js';
import SwitchCard from "./SwitchCard.js";

export default function Categories2Item(props)  {
  const { response, parentId } = useCategoryRelatives();

  const catList = response.payload.map(cat => 
    <SwitchCard parentId={parentId} item={cat} key={cat.id}/>
  );

  return(
    <>
    <div className="mt-4 pt-2" style={{borderTop: '1px solid #EEEEEE'}}>Vælg hvilke kategorier, der skal indeholde denne ting:</div>
    <div className="row mt-2">{catList}</div>
    </>
  );
}