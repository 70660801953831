import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';

const BookingSlotsContext = createContext();

export const useBookingSlots = () => useContext(BookingSlotsContext);

export default function BookingSlotsProvider(props)  {

  const { response, error, loading } = useDataFetch( props.fetchOptions);

  const [selectedItem, setSelectedItem] = useState({key: 0, id: 0, name: ""});

  const reload = () => props.setOptions((oldOptions) => {return {...oldOptions}} )
  const parentId = props.catId;

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter bookingmuligheder...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <BookingSlotsContext.Provider value={{selectedItem, setSelectedItem, response, error, loading, reload, parentId}}>
      {props.children}
    </BookingSlotsContext.Provider>
  );
}