import { useActiveBookings } from '../providers/ActiveBookingsProvider.js';
import AlertDialog from "../forms/AlertDialog.js";
import { useState } from 'react';
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";

export default function BookingList()  {
  const { response, reload } = useActiveBookings();
  const [selectedBooking, setSelectedBooking] = useState(false);
  const dateDisplayOptions = { weekday: 'long', day: 'numeric', month: 'long' };
  const [options, setOptions] = useState({ url: "", query: null });
  const {error: hasError, loading: isLoading } = useDataFetch(options);

  const startDeleteBooking = (booking) => {
    setSelectedBooking(booking);
  }

  let id=0;
  const bookingList = response.payload.map(booking => {
    booking.day = new Date(booking.date);
    id++;
    return(<ListGroup.Item key={id}>
      <Row>
        <Col sm={4} lg={5} className="Capital">{booking.day.toLocaleDateString('da-DK',dateDisplayOptions)}, kl: {String(booking.slot).padStart(4,'0').substring(0,2)+':'+String(booking.slot).padStart(4,'0').substring(2,4)}</Col>
        <Col sm={4} lg={5}>{booking.item} ({booking.cat})</Col>
        <Col sm={4} lg={2}><Button variant="outline-danger" onClick={() => {startDeleteBooking(booking)}}>Slet denne</Button></Col>
      </Row>
    </ListGroup.Item>
    );
  });

  const doDelete = () => {
    console.log(selectedBooking);
    setOptions({
      url: process.env.REACT_APP_APIURL+"booking",
      method: "DELETE",
      onDone: reload,
      body: {
        id: 666,
        catId: 0,
        itemId: selectedBooking.id,
        slot: selectedBooking.slot,
        ruleId: 0,
        theDate: selectedBooking.day.toISOString().split('T')[0] 
      }
    });
    setSelectedBooking(false);
  }

  const deleteProps = { 
    displaySet: setSelectedBooking, 
    handleAccept: doDelete,
    title: "Vil du slette denne booking?",
    okText: "Slet den"
  }

  if (isLoading) {
    return (<><ClockLoader/> Sletter booking...</>);
  }

  if (hasError) {
    return(<div>Noget gik galt under sletningen af din booking!</div>);
  }

  if (response.payload && response.payload.length==0) {
    return(<div>Du har ingen nuværende fremtidige bookinger.</div>);
  }

  return (
    <>
    <ListGroup defaultActiveKey="">
      {bookingList}
    </ListGroup>
    <AlertDialog {...deleteProps} show={selectedBooking}>
        Vil du slette din boooking {selectedBooking ? selectedBooking.day.toLocaleDateString('da-DK',dateDisplayOptions) : '' }
        , kl: {selectedBooking ? String(selectedBooking.slot).padStart(4,'0').substring(0,2)+':'+String(selectedBooking.slot).padStart(4,'0').substring(2,4) : ''}.
      </AlertDialog>
    </>
  )
}