import { ListGroup, Col, Button } from "react-bootstrap";
import Category from './Category.js';
import { useCategory } from '../providers/CategoriesProvider.js';

export default function Categories()  {
  const { selectedCat, setSelectedCat, response, reload } = useCategory();
    
  const newCat = () => {
    setSelectedCat({key: -1, id: -1, name: "", maxSame: 1, maxConcurrent: 1});
  }
  
  const catList = response.payload.map(cat => 
    <ListGroup.Item 
      key={cat.id} 
      action 
      onClick={() => setSelectedCat({...cat, key: cat.id})}>
      {cat.id !== selectedCat.id ? cat.name : <b>{cat.name}</b>}
    </ListGroup.Item>);

  return (
    <>
    <Col sm={4} lg={2}>
    <Button variant="outline-primary container" className="mb-1 mt-1" onClick={newCat}>Tilføj kategori</Button>
    <ListGroup defaultActiveKey="">
      {catList}
    </ListGroup>
    </Col>
    <Col sm={8} lg={10}>
     <Category {...selectedCat} topUpdate={reload} catUpdate={setSelectedCat}  />
    </Col>
    <div></div>
    </>
  )
}