import Card from "react-bootstrap/Card";
import {Row, Col} from "react-bootstrap";
import { useSysState } from '../context/SysStateContext';
import LoginForm from "../forms/LoginForm";


function Login() {
  const systate = useSysState(); // Remember that the state data is within state property!

  const logoff = () => {
    const action = { type: "logoff" }
    systate.dispatch(action);
  }

  return (
    <Row className="justify-content-lg-center">
    <Col md="auto">
    { systate.state.auth && (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Log af</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Klik her for at logge af</Card.Subtitle>
        <button onClick={logoff}>Log off</button>
      </Card.Body>
    </Card>)}
    { !systate.state.auth && (
      <LoginForm />
    )}
    </Col>
    </Row>
  );
}

export default Login;
