import Card from "react-bootstrap/Card";

export default function About() {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Om HelleFor</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">En hjælp til at deles om tingene...</Card.Subtitle>
        <Card.Text>
          <Card.Title>Hvad kan det bruges til?</Card.Title>
          <p>HelleFor er et lille system, der gør det nemt for grupper af personer at deles om fælles ting.</p>
          <p>Vil man låne en fælles ting, så booker man den i systemet, så andre kan se at den er "optaget".</p>
          <p>Der kan sættes regler op for hvornår en given ting kan bookes, og hvor mange gange en bruger kan booke den samme ting - Eller ting inden for samme kategori.</p>
          <p>For eksempel: En gruppe jægere deles om en række jagttårne. De sætter systemet op, så tårne kan bookes enten den første- eller sidste halvdel af dagen <i>og</i>
          de spærrer for bookinger den 16. og 17. maj (Hvor bukkejagten starter, så <i>alle</i> jægere vil ud, og de trækker derfor lod om tårnene disse dage).<br/>
          Desuden begrænser de antal <i>fremtidige</i> bookinger til maks to pr. tårn og fire i alt. 
          (Når tiden passerer en booking, så tæller den ikke længere med i disse totaler, og brugeren kan booke igen)</p>
          <Card.Title>Alle grupper der deler ting, kan bruge systemet</Card.Title>
          <p>Det er ikke kun jægere, der kan have gavn af HelleFor!</p>
          <p>Kajakklubben, der deles om kajakker kan bruge det. Det samme kan rideklubben hvor rytterne deles om klubbens heste.</p>
          <p>De forskellige ting, der deles om opdeles i kategorier så det bliver nemmere at overskue.</p>
          <Card.Title>Koster det noget?</Card.Title>
          <p>Ja, det koster at drive og videreudvikle et system, så derfor koster HelleFor 300,- om året for en gruppe på op til 10 personer med 15 ting at deles om.</p>
          <p>Er i en mindre gruppe, så kan det være billigere, men så skal i booke flere år af gangen (Det tager jo tid at registrere jeres betaling etc.)</p>
          <p>Skriv til Robert.k.Madsen@gmail.com hvis du er interesseret i at gøre brug af systemet, så kan vi nok finde ud af noget!</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
