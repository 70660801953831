import { Card } from "react-bootstrap";

export default function CategorySelectCard(props)  {
  return (
    <Card role="button" 
        style={{ width: '18rem', height: '10rem', marginRight: '0.3em', overflow: 'hidden' }} 
        className={"col-lg-4 col-md-3 col-sm-2 mb-1 pb-2 bg-light"}
        onClick={() => props.selectCat(props.item)}
        >
        <Card.Body style={{overflow: 'hidden'}}>
          <Card.Title className="text-truncate">{props.item.name}</Card.Title>
          <Card.Text>
            {props.item.descr}
          </Card.Text>
        </Card.Body>
    </Card>
  );
}