import { useSysState } from '../context/SysStateContext';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function NoRealmAccess() {
  const sysState = useSysState().state; // Remember that the state data is within state property!
  const navigate = useNavigate(); 

  const realmCount = Object.getOwnPropertyNames(sysState.realms).length;

  return (<div>Du har ikke adgang til denne gruppe.<br/>Kontakt gruppens administrator, hvis du mener, at du skal have adgang.<br/>
      {realmCount > 0 && 
        <div><Button variant="outline-primary" onClick={() => {navigate('/switch')}}>Klik for at gå til en anden gruppe</Button></div>
      } 
      </div>
  );
}