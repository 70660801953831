import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

export default function RealmCard(props)  {
  const centerStyle={ textAlign: 'center'}
  return (
    <Card style={{ height: '6rem', marginRight: '0.3em', overflow: 'hidden' }} //width: '18rem',
        className={"mb-1 pb-2 bg-light"}
        >
        <Card.Body style={{overflow: 'hidden'}}>
          <Card.Title className="text-truncate" style={centerStyle}>{props.name}</Card.Title>
          <Card.Text style={centerStyle}>
          <Button variant="outline-secondary" style={{marginRight: '4px'}} onClick={(e) => props.selectRealm(e,props.id,false)} disabled={props.active}>Vælg</Button>
          <Button variant="outline-secondary"  onClick={(e) => props.selectRealm(e,props.id,true)} disabled={props.active}>Vælg og husk</Button>
          </Card.Text>
        </Card.Body>
    </Card>
  );
}