import { Card } from "react-bootstrap";
import { useState, useRef } from 'react';
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { useSysState } from '../context/SysStateContext';

export default function BookingCard(props)  {
  const [curState, setNewState] = useState(); //props.item._rel != null);
  const sysState = useSysState().state; // Remember that the state data is within state property!
  const [show, setShow] = useState(false);
  const target = useRef(null);

  let style='text-white bg-success';
  if (!curState) {
    style='bg-light';
  }

  const dateDisplayOptions = { weekday: 'long' };
  const dateStr = props.o.dateObj.toISOString().split('T')[0] ;
  const bookings = props.o.bookings[dateStr];
  const bookingOptions = [];
  let id=0;
  let variant = '';
  Object.getOwnPropertyNames(props.slots).forEach((startTime) => {
    if (startTime !== 'length') {
      let isBooked = bookings && startTime in bookings;
      const bySelf = isBooked && (bookings[startTime].self===1);
      let byWho = 0;
      if (isBooked && !bySelf) {
        byWho = bookings[startTime].bookedFor;
      }
      isBooked = isBooked || props.onlyCancel;
      if (bySelf) {
        isBooked = false;
        variant = '';
      } else {
        variant = 'outline-';
      }
      const startTxt = String(startTime).padStart(4,'0');
      const endTxt = String(props.slots[startTime]).padStart(4,'0');
      bookingOptions.push(<><div className="bookBtnCtn"><Button variant={variant+"success"} size="sm" className="mb-2 bookBtn" key={id++} onClick={() => props.o.book(startTime, props.o.dateObj, bySelf)} disabled={isBooked}>
        {startTxt.substring(0,2)+':'+startTxt.substring(2,4)} - {endTxt.substring(0,2)+':'+endTxt.substring(2,4)}&nbsp;&nbsp;
        {bySelf?<span className="btn-close selfBook"/>:''}</Button>
        {byWho?<><img className="whoBook" src={process.env.PUBLIC_URL + "/gfx/user-flat.svg"}  ref={target} onClick={() => setShow(!show)} title={sysState.realmUsers[byWho]}/>
        <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {sysState.realmUsers[byWho]}
          </Tooltip>
        )}
        </Overlay></>
        :''}
        </div></>);
    }
  });

  return (
    <Card key={props.theKey} id={'bc'+props.theKey}
      style={{ width: '11rem', marginRight: '0.3em', overflow: 'hidden' }} 
      className={"col-lg-4 col-md-3 col-sm-2 mb-1 pb-2 "+style}
      >
      <Card.Body style={{overflow: 'hidden'}}>
        <Card.Title className="text-truncate Capital">{props.o.dateObj.toLocaleDateString('da-Dk',dateDisplayOptions)} {props.o.dateObj.getDate()}.</Card.Title>
        <Card.Text>
          {bookingOptions.length > 0 ? bookingOptions : <>Ingen tider<br/>denne dag.</> }
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
