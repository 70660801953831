import Card from "react-bootstrap/Card";

function NotFound() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Not Found</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Siden du søger, findes ikke.</Card.Subtitle>
        <Card.Text>
          Klik på menupunkterne foroven for at komme tilbage til systemet.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default NotFound;
