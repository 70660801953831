import Login from './Login.js';
import ChangePasswordForm from '../forms/ChangePasswordForm.js';
import { useSysState } from '../context/SysStateContext';
import { Row, Col, Container } from "react-bootstrap";

export default function User()  {
  const sysstate = useSysState(); // Remember that the state data is within state property!

  return(
    <Container>
    <Row>
      <Col>Du er logget ind som: <i>{sysstate.state.name}</i></Col>
    </Row>
    <Login/>
    <ChangePasswordForm/>
    </Container>
  );
}