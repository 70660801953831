import { Row, Container, Navbar, Nav } from "react-bootstrap";
import { useState } from "react";
import CategoriesProvider from "../providers/CategoriesProvider.js";
import BookableItemsProvider from "../providers/BookableItemsProvider.js";
import Categories from "../pages/Categories";
import Users from "./Users";
import Bookingsprovider from "../providers/BookingsProvider.js";

export default function Setup() {
  const [subCat, setSubCat] = useState('');

  const handleSelect = (eventKey) => setSubCat(eventKey);

  return(
    <>
    <Row>
      <Navbar bg="light" expand="lg">
        <Container >
        <Navbar.Collapse id="basic-navbar-nav" className="align-items-center justify-content-center">
            <Nav variant="pills" onSelect={handleSelect}>
              <Nav.Link className="HF-setup" eventKey="categories" >Kategorier</Nav.Link>
              <Nav.Link className="HF-setup" eventKey="items">Ting at booke</Nav.Link>
              <Nav.Link className="HF-setup" eventKey="users">Brugere</Nav.Link>
              <Nav.Link className="HF-setup" eventKey="bookings">Bookinger</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Row>
    <Row>
      {subCat === 'categories' && <CategoriesProvider><Categories /></CategoriesProvider> }
      {subCat === 'items' && <BookableItemsProvider /> }
      {subCat === 'users' && <Users /> }
      {subCat === 'bookings' && <Bookingsprovider /> }
    </Row>
    </>
  );
}