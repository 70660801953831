import { BrowserRouter, useRoutes } from 'react-router-dom';
import { useSysState } from '../context/SysStateContext'; 
import BasePage from '../BasePage';
import Login from '../pages/Login';
import User from '../pages/User';
import BookingTop from '../pages/BookingTop';
import About from '../pages/About';
import Setup from '../pages/Setup';
import SwitchRealm from '../pages/SwitchRealm';
import FAQ from '../pages/FAQ';
import NotFound from '../NotFound';
import NoRealmAccess from '../pages/NoRealmAccess';

export default function BrowserNavigation(props) {
  const sysState = useSysState().state; // Remember that the state data is within state property!

  let path = [];
  if (!sysState.auth) {
    path = [{path: props.path+"login", element: <BasePage subelement={<Login/>}/> },
        {path: props.path, element: <BasePage subelement={<Login/>}/> }
      ];
  } else {
    if (sysState.realm === 0) {
      path = [{path: props.path+"login", element: <BasePage subelement={<User/>}/> }];
    } else {
      path = [{path: props.path+"booking", element: <BasePage subelement={<BookingTop/>}/> },
          {path: props.path, element: <BasePage subelement={<BookingTop/>}/> },
          {path: '/reactbase/app/build', element: <BasePage subelement={<BookingTop/>}/> },
          {path: props.path+"login", element: <BasePage subelement={<User/>}/> },
          {path: props.path+"faq", element: <BasePage subelement={<FAQ/>}/> }
        ];
      if (sysState.lvl >= 80) {
        path.push({path: props.path+"setup", element: <BasePage subelement={<Setup/>} /> });    
      }
    }
  }
  path.push({path: props.path+"about", element: <BasePage subelement={<About/>} /> });
  path.push({path: props.path+"switch", element: <BasePage subelement={<SwitchRealm/>} /> });
  path.push({path: props.path+"noaccess", element: <BasePage subelement={<NoRealmAccess realms={sysState.realms}/>} /> });
  path.push({path: "*", element: <BasePage subelement={<NotFound/>} /> });
  
  let MyRoutes = () => useRoutes(path);

  return(
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
}