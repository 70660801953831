import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';
import BookableItems from "../pages/BookableItems";

const ItemContext = createContext();

export const useItem = () => useContext(ItemContext);

export default function BookableItemsProvider({children})  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"items/", query: { page: 1, pageSize: 10, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);

  const [selectedItem, setSelectedItem] = useState({key: 0, id: 0, name: "", descr: ""});

  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter ting, der kan bookes...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <ItemContext.Provider value={{selectedItem, setSelectedItem, response, error, loading, reload}}>
      <BookableItems />
    </ItemContext.Provider>
  );
}