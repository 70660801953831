import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export default function CategoriesProvider({children})  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"categories/", query: { page: 1, pageSize: 10, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);

  const [selectedCat, setSelectedCat] = useState({key: 0, id: 0, name: "", descr: ""});

  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter kategorier...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <CategoryContext.Provider value={{selectedCat, setSelectedCat, response, error, loading, reload}}>
      {children}
    </CategoryContext.Provider>
  );
}