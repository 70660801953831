import { Card, Button, Stack } from "react-bootstrap";
export default function Booking(props)  {
  return (
    <Card key={props.booking.idx} className="mb-2">
      <Card.Body>
        <Stack gap={2}>
          <Stack direction="horizontal" gap={3}>
          <div className="mb-1 text-muted me-auto">{props.p.users[props.booking.u]}, {props.booking.dStr}, kl: {String(props.booking.s).padStart(4,'0').substring(0,2)+':'+String(props.booking.s).padStart(4,'0').substring(2,4)}</div>
          <Button variant="outline-danger" onClick={() => props.booking.deleteFunc(props.booking)}>Slet</Button>
          </Stack>
          <div>{props.p.cats[props.booking.c]}, {props.p.items[props.booking.i]}</div>
        </Stack>
      </Card.Body>
    </Card>
  );
}