export default class RuleHelper {
  rule = null;
  maxBefore = null;
  dayExcl = null;

  constructor(ruleObj) {
    this.rule = ruleObj;
    this.maxBefore = new Date(Date.now()+(ruleObj.maxbefore*86400000));
    this.rule.dayexcl.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));
    this.dayExcl = new Array(this.rule.dayexcl.length);
    this.rule.dayexcl.forEach((excl, i) => this.dayExcl[i] = new DayExclutions(excl));
  }

  getSlotsForDate(date) {
    const slotId = this.getSlotsIdForDate(date);
    if (slotId === '') {
      return [];
    } else {
      return this.rule.slotgroups[slotId];
    }
  }

  getSlotsIdForDate(date) {
    let slot = '';
    for (let excl of this.dayExcl) {
      slot = excl.getSlotIdForDate(date);
      if (slot !== '') {
        return slot;
      }
    }
    return '';
  }
}

class DayExclutions {
  excl = null;

  constructor(exclObj) {
    this.excl = exclObj;
  }

  getSlotIdForDate(date) {
    if (this.excl.weekd) {  // Excluded weekday
      if (this.excl.weekd.includes(date.getDay())) {
        return '';
      }
    }
    if (this.excl.hollid) {
      // Check hollidays
    }
    if (this.excl.list) {
      if (this.excl.list.includes(date.toLocaleDateString('es-CL').substring(0,5))) {
        return '';
      }
    }
    return this.excl.slots;
  }
}