import { useBookingSlots } from '../providers/BookingSlotsProvider.js';
import { useState, useEffect } from 'react';
import { Alert } from "react-bootstrap";
import DateHelper from '../util/DateHelper.js';
import RuleHelper from '../util/RuleHelper.js';
import BookingCard from './BookingCard.js';
import AlertDialog from "../forms/AlertDialog.js";
import useDataFetch from "../hooks/useDataFetch";

export default function SelectSlots(props)  {
  const { response, reload } = useBookingSlots();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [options, setOptions] = useState({ url: "", query: null });
  const [selectedSlot, setselectedSlot] = useState({ slot: 0, day: null});

  const { error: hasError } = useDataFetch(options);

  const rule = response.payload[0].rule;
  const ruleHelper = new RuleHelper(rule.def);
  const dhelper = new DateHelper(props.o.date);
  const days = dhelper.getDatesToMonthShift();
  const dateDisplayOptions = { weekday: 'long', day: 'numeric', month: 'long' };

  const bookings = {};
  response.payload[0].bookings.forEach((bArray) => {
    if (!bookings[bArray[2]]) {
      bookings[bArray[2]] = {}
    }
    bookings[bArray[2]][bArray[1]] = {self: bArray[3], bookedFor: bArray[4]};
  });

  useEffect(() => {
      // wait for a paint before running code dependent on drawn items.
      if (props.o.scrollPos >= 0) {
        window.requestAnimationFrame(function() {
          document.documentElement.scrollTop = document.body.scrollTop = props.o.scrollPos;
        });
      }
  },[props.o]);

  const bookSlot = (slot,day,bySelf) => {
    props.o.setScroll(document.documentElement.scrollTop || document.body.scrollTop);
    if (bySelf) {
      setselectedSlot({slot: slot, day: day});
      setShowDeleteAlert(true);
      return;
    }
    setOptions({
      url: process.env.REACT_APP_APIURL+"booking",
      method: "POST",
      onDone: reload,
      body: {
        id: 0,
        catId: props.o.cat.id,
        itemId: props.o.itemId,
        slot: parseInt(slot),
        ruleId: 0,
        theDate: day.toISOString().split('T')[0] 
      }
    });
  }

  const doDelete = () => {
    setShowDeleteAlert(false);
    setOptions({
      url: process.env.REACT_APP_APIURL+"booking",
      method: "DELETE",
      onDone: reload,
      body: {
        id: 666,
        catId: props.o.cat.id,
        itemId: props.o.itemId,
        slot: parseInt(selectedSlot.slot),
        ruleId: 0,
        theDate: selectedSlot.day.toISOString().split('T')[0] 
      }
    });
  }

  const deleteProps = { 
    displaySet: setShowDeleteAlert, 
    handleAccept: doDelete,
    title: "Vil du slette denne booking?",
    okText: "Slet den"
  }

  const itemMaxReached = response.payload[0].future.thisItem >= props.o.cat.maxsame;
  const catMaxReached = response.payload[0].future.otherBookingsInCat+response.payload[0].future.thisItem >= props.o.cat.maxconcurrent;

  const baseObject = {...props.o, 
      ruleId: rule.id, 
      bookings: bookings, 
      book: bookSlot, 
      showAlert: setShowDeleteAlert,
  }
  const slotList = days.map((day,i) => {
    const o = {...baseObject, dateObj: day };
    return(
      <BookingCard key={i} theKey={i} o={o} slots={ruleHelper.getSlotsForDate(day)} onlyCancel={catMaxReached || itemMaxReached}/>
    );
  });

  return (
    <div className="row mt-2" style={{margin: "0.5em"}}>
      {itemMaxReached && 
        <Alert className="mt-2" variant="warning">Du kan ikke have flere fremtidige bookinger af denne ting.</Alert>
      }
      {catMaxReached && 
        <Alert className="mt-2" variant="warning">Du kan ikke have flere fremtidige bookinger i denne kategori.</Alert>
      }
      {hasError &&
        <Alert className="mt-2" variant="danger">Der skete en fejl! - Bookingen kunne ikke behandles</Alert>
      }
      {slotList}
      <AlertDialog {...deleteProps} show={showDeleteAlert}>
        Vil du slette din boooking {selectedSlot.day?selectedSlot.day.toLocaleDateString('da-DK',dateDisplayOptions):''}
        , kl: {String(selectedSlot.slot).padStart(4,'0').substring(0,2)+':'+String(selectedSlot.slot).padStart(4,'0').substring(2,4)}.
      </AlertDialog>
    </div>
  );
}