import UserNavigation from "./UserNavigation";
import { NavLink } from 'react-router-dom';
import { Container, Alert } from "react-bootstrap";
import { useSysState } from '../context/SysStateContext';

function Header() {
    const sysState = useSysState().state; // Remember that the state data is within state property!
    let warning = [];
    const divStyle = {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };
    const iconStyle = {
      height: 15,
      marginLeft: '4px',
      top: '-3px',
      position: 'relative'
    }
    const linkStyle = {
      textDecoration: 'none',
      color: '#000'
    }
    const realmCount = Object.getOwnPropertyNames(sysState.realms).length;
    sysState.warnings.map((item,index) => {
      warning.push(<Alert variant='warning' key={index}>{item}</Alert>);
    });
    const hasWarning = warning.length > 0;
    
    return (
      <div className=" border-bottom mb-1">
        <Container fluid>
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
          <div className="col-md-3"><b>HelleFor</b></div>
          <UserNavigation path="/"/>
          <div className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none text-end" style={divStyle}>
          {realmCount > 1 ?
            <NavLink to="/switch" style={linkStyle}><b>{sysState.realmname}</b><img src={process.env.PUBLIC_URL + "/gfx/replace.svg"} style={iconStyle} alt="Skift gruppe"/></NavLink> :
            <b>{sysState.realmname}</b>
          }
          </div>
        </header>
        </Container>
        {hasWarning && warning }
      </div>);
  }
  
  export default Header;