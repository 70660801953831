import { useState, useEffect } from 'react';
import CategoriesProvider from "../providers/CategoriesProvider.js";
import CategorySelectCards from '../pageparts/CategorySelectCards.js';
import ItemSelectCards from '../pageparts/ItemSelectCards.js';
import Items2CategoryProvider from '../providers/Items2CategoryProvider.js';
import BookingSlotsProvider from '../providers/BookingSlotsProvider.js';
import SelectSlots from '../pageparts/SelectSlots.js';
import { Col, Button, Row } from "react-bootstrap";

export default function BookingSelect(props)  {
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedItem, stateSetSelectedItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [backButtonActive, setBackButtonActive] = useState(false);
  const [storedScrollPos,setScrollPos] = useState(-1);

  const [options, setOptions] = useState({ url: '',
  query: { page: 1, pageSize: 1000, upd: Date.now() }, selected: 0});

  const clearSelectedCat = () => {
    setSelectedCat(null);
    setSelectedItem(null);
  }

  const changeMonth = (e,direction) => {
    e.preventDefault();
    let nextDate = new Date(selectedDate.getFullYear(),selectedDate.getMonth()+direction,1);
    let currentDate = new Date();
    if (nextDate < currentDate) nextDate = currentDate;
    setSelectedDate(nextDate);
    setOptions((oldOptions) => {
      return {...oldOptions, url: process.env.REACT_APP_APIURL+"items/"+selectedItem.id+'/slots/'+nextDate.getFullYear()+'-'+String(nextDate.getMonth()+1).padStart(2,'0')+'/catId/'+selectedCat.id}
    });
  }

  const setSelectedItem = (item) => {
    setScrollPos(-1);
    stateSetSelectedItem(item);
    if (!item) {
      return;
    }
    setOptions((oldOptions) => {
      return {...oldOptions, url: process.env.REACT_APP_APIURL+"items/"+item.id+'/slots/'+selectedDate.getFullYear()+'-'+String(selectedDate.getMonth()+1).padStart(2,'0')+'/catId/'+selectedCat.id}
    });
  }

  useEffect(() => {
    setBackButtonActive(selectedDate > new Date());
  },[selectedDate]);

  if (selectedCat == null) {
    return (
      <CategoriesProvider><CategorySelectCards selectCat={setSelectedCat}/></CategoriesProvider>
    );
  }

  if (selectedItem == null) {
    return(
      <>
        <Row className="mt-2 mb-3">
          <Col sm={2}><Button variant="secondary" onClick={() => clearSelectedCat()}>Ny Kategori</Button></Col>
          <Col sm={10}><h2>{selectedCat.name}</h2></Col>
        </Row>
      <Items2CategoryProvider catId={selectedCat.id} query=''><ItemSelectCards selectItem={setSelectedItem}/></Items2CategoryProvider>
      </>
    );
  }
  const dateDisplayOptions = { month: 'long', year: 'numeric' };

  const obj = { cat: selectedCat, itemId: selectedItem.id, date: selectedDate, scrollPos:storedScrollPos, setScroll:setScrollPos };

  return(
    <>
    <Row className="mt-2 mb-3 fluid">
      <Col sm={6}><Row><Col sm={4} xs={5}><Button variant="secondary" onClick={() => clearSelectedCat()}>Ny Kategori</Button></Col><Col sm={8} xs={7}><h2>{selectedCat.name}</h2></Col></Row></Col>
      <Col sm={6}><Row><Col sm={4} xs={5}><Button variant="secondary" onClick={() => setSelectedItem(null)}>Ny Ting</Button></Col><Col sm={8} xs={7}><h2>{selectedItem.name}</h2></Col></Row></Col>
    </Row>
    <Row className="text-muted">Vælg hvornår du vil booke ...</Row>
    <Row>
      <Col sm={3} xs={3}><Button variant="outline-secondary" onClick={(e) => changeMonth(e,-1)} disabled={!backButtonActive}>Forrige måned</Button></Col>
      <Col sm={6} xs={6}><h2 className="Capital">{selectedDate.toLocaleDateString('da-DK', dateDisplayOptions)}</h2></Col>
      <Col sm={3} xs={3}><Button variant="outline-secondary" onClick={(e) => changeMonth(e,1)}>Næste måned</Button></Col>
    </Row>
    <BookingSlotsProvider itemId={selectedItem.id} date={selectedDate} fetchOptions={options} setOptions={setOptions}><SelectSlots o={obj} startDate={selectedDate}/></BookingSlotsProvider>
  </>
);

}