import { Form, Button, Alert } from "react-bootstrap";
import { useState } from 'react';
import useDataFetch from "../hooks/useDataFetch";
import AlertDialog from "../forms/AlertDialog.js";
import CustomListDropDown from "../pageparts/CustomListDropDown.js";
import Categories2ItemProvider from "../providers/Categories2ItemProvider.js";

export default function BookableItem(props)  {
  const [id] = useState(props.id);
  const [name, setName] = useState(props.name);
  const [descr, setDescr] = useState(props.descr);
  const [ruleId, setRuleId] = useState(props.ruleid);
  const [options, setOptions] = useState({ url: "", query: null });
  const {error: hasError, loading: isLoading } = useDataFetch(options);
  const [lastResponse, setLastResponse] = useState({});
  // For delete button
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const doDelete = e => {
    setShowDeleteAlert(false);
    setOptions({
      url: process.env.REACT_APP_APIURL+"items/"+id,
      method: "DELETE",
      onDone: onItemDelete,
      body: {
        id: id
      }
    });
  }
  
  const deleteProps = { 
      displaySet: setShowDeleteAlert, 
      handleAccept: doDelete,
      title: "Vil du slette denne ting?",
      okText: "Slet den"
  }

  const onItemUpdate = (response) => {
    setLastResponse(response);
    if (response.status && response.status < 300) {
      props.topUpdate((oldState) => { return {...oldState, selected: response.payload.id} });
      props.itemUpdate((oldItem) => { return {...oldItem, name: name, descr: descr, id: response.payload.id, ruleid: ruleId}})
    }
  }

  const startDelete = e => {
    setShowDeleteAlert(true);
  }

  const onItemDelete = (response) => {
    setLastResponse(response);
    if (response.status && response.status < 300) {
      props.topUpdate((oldState) => { return {...oldState, selected: -1} });
      props.itemUpdate({key: -1, id: -1, name: ""});
    }
  }

  const submit = e => {
    e.preventDefault();
    setOptions({
      url: process.env.REACT_APP_APIURL+"items/"+(id > 0 ? id : ''),
      method: (id > 0 ? "PUT" : "POST"),
      onDone: onItemUpdate,
      body: {
        id: id,
        name: name,
        descr: descr,
        ruleid: ruleId,
      }
    });
  };

  if (props.id === 0) {
    return(<div>Vælg en "ting"</div>);
  }

  return(
    <>
    <Form onSubmit={submit}>
    <input type="hidden" name="itemId" id="itemId" value={id} readOnly/>
    <Form.Group className="mb-3" controlId="formItemName">
      <Form.Label>Tingens navn</Form.Label>
      <Form.Control 
        type="text" 
        placeholder="Hvad vil du kalde tingen" 
        value={name} 
        onChange={event => setName(event.target.value)}
        required/>
      <Form.Text className="text-muted">
        Navnet skal være mindst 4 tegn langt.
      </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formItemDescr">
      <Form.Label>Kort beskrivelse</Form.Label>
      <Form.Control 
        as="textarea" rows={3} 
        placeholder="En evt. beskrivelse" 
        onChange={event => setDescr(event.target.value)}
        value={descr}/>
    </Form.Group>

    <CustomListDropDown repository="rules" onChange={setRuleId} selected={ruleId} label="Vælg et setup af mulige bookinger"/>

    <Button variant="primary" type="submit" disabled={isLoading}>
      { id > 0 && !isLoading && "Opdatér ting"}
      { id === -1 && !isLoading && "Opret ting"}
      { isLoading && "Opdaterer..."}
    </Button>
    { id > 0 && !isLoading &&
      <>
      &nbsp;
      <Button variant="warning" onClick={() => { startDelete(id)}}>Slet ting</Button>
      <AlertDialog {...deleteProps} show={showDeleteAlert}>
        <i>Bemærk:</i> Hvis der er tilknyttet fremtidige bookinger til denne, så kan den ikke slettes.
      </AlertDialog>
       </>
    }
    {(hasError || (lastResponse.status && lastResponse.status >= 300)) &&
      <Alert className="mt-2" variant="warning">
        <p>{lastResponse.payload.error}</p>
      </Alert>}
  </Form>
    { id > 0 && !isLoading && <Categories2ItemProvider itemId={id} query='all'/> }
  </>
  );
}