import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';
import Categories2Item from "../pageparts/Categories2Item";

const CategoryRelativesContext = createContext();

export const useCategoryRelatives = () => useContext(CategoryRelativesContext);

export default function Categories2ItemProvider(props)  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"items/"+props.itemId+'/categories/'+props.query, query: { page: 1, pageSize: 1000, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);

  const [selectedCat, setSelectedCat] = useState({key: 0, id: 0, name: ""});

  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )
  const parentId = props.itemId;

  if (loading) {
    return <div className="loading"><ClockLoader/> Henter tilknyttede kategorier...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <CategoryRelativesContext.Provider value={{selectedCat, setSelectedCat, response, error, loading, reload, parentId}}>
      <Categories2Item />
    </CategoryRelativesContext.Provider>
  );
}