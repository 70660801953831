import { useCategory } from '../providers/CategoriesProvider.js';
import CategorySelectCard from "./CategorySelectCard.js";

export default function CategorySelectCards(props)  {
  const { response } = useCategory();

  const catList = response.payload.map(cat => 
    <CategorySelectCard item={cat}  key={cat.id} selectCat={props.selectCat}/>
  );
  return (
    <div className="row mt-2" style={{margin: "0.5em"}}><div className="text-muted">Vælg hvilken kategori du vil booke i...</div>{catList}</div>
  );
}