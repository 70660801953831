import { useState } from "react";
import { Row, Col, Container, Navbar, Nav, NavLink } from "react-bootstrap";
import BookingSelect from "./BookingSelect";
import ActiveBookingsProvider from "../providers/ActiveBookingsProvider";

export default function BookingTop() {
  const [subCat, setSubCat] = useState('book');
  const handleSelect = (eventKey) => setSubCat(eventKey);

  return (
    <>
    <Row>
      <Navbar bg="light" className="navbar-expand  navbar-expand-lg  navbar-expand-sm" expand={true}>
        <Container  className="align-items-center justify-content-center">
        <Navbar id="basic-navbar-nav"  className="navbar-expand  navbar-expand-lg  navbar-expand-sm">
            <Nav variant="pills" onSelect={handleSelect}>
              <Nav.Link className={"HF-setup"+(subCat==='book' && ' active')} eventKey="book" >Book!</Nav.Link>
              <Nav.Link className="HF-setup" eventKey="mybookings">Mine Bookinger!</Nav.Link>
            </Nav>
        </Navbar>
        </Container>
      </Navbar>
    </Row>
    <Row>
      {subCat === 'book' && <BookingSelect/> }
      {subCat === 'mybookings' && <ActiveBookingsProvider/> }
    </Row>
    </>

  );
}
