import useDataFetch from "../hooks/useDataFetch";
import ClockLoader from "react-spinners/ClockLoader";
import { createContext, useState, useContext } from 'react';

const ItemRelativesContext = createContext();

export const useItemRelatives = () => useContext(ItemRelativesContext);

export default function Items2CategoryProvider(props)  {
  const [options, setOptions] = useState({ url: process.env.REACT_APP_APIURL+"categories/"+props.catId+'/items/'+props.query, query: { page: 1, pageSize: 1000, upd: Date.now() }, selected: 0});
  const { response, error, loading } = useDataFetch( options);

  const [selectedItem, setSelectedItem] = useState({key: 0, id: 0, name: ""});

  const reload = () => setOptions((oldOptions) => {return {...oldOptions}} )
  const parentId = props.catId;



  if (loading) {
    return <div className="loading"><ClockLoader/> Henter ting i kategorien...</div>;
  }
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }
  return (
    <ItemRelativesContext.Provider value={{selectedItem, setSelectedItem, response, error, loading, reload, parentId}}>
      {props.children}
    </ItemRelativesContext.Provider>
  );
}