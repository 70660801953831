import { Card } from "react-bootstrap";
import { useState } from 'react';
import ClockLoader from "react-spinners/ClockLoader";
import useDataFetch from "../hooks/useDataFetch";

export default function SwitchCard(props)  {
  const [curState, setNewState] = useState(props.item._rel != null);
  const [options, setOptions] = useState({ url: "", query: null });
  const {error: hasError, loading: isLoading } = useDataFetch(options);

  let style='text-white bg-success';
  if (!curState) {
    style='bg-light';
  }

  const toggleCat = (state) => {
    setOptions({
      url: process.env.REACT_APP_APIURL+"items/"+props.parentId+'/categories/'+props.item.id,
      method: state ? "DELETE" : "POST",
      onDone: onCatToggled,
      body: { }
    });
  }

  const onCatToggled = (response) => {
    setNewState(response.payload.selected);
  }
  
  if (hasError) {
    return (<div>Fejl! - Kunne ikke opdatere data!</div>);
  }

  return (
    <Card role="button" 
        style={{ width: '18rem', height: '10rem', marginRight: '0.3em', overflow: 'hidden' }} 
        className={"col-lg-4 col-md-3 col-sm-2 mb-1 pb-2 "+style}
        onClick={(loading) => { if (!isLoading) { toggleCat(curState)}}}
        >
      { isLoading ? <><ClockLoader/> ... opdaterer</> : 
        <Card.Body style={{overflow: 'hidden'}}>
          <Card.Title className="text-truncate">{props.item.name}</Card.Title>
          <Card.Text>
            {props.item.descr}
          </Card.Text>
        </Card.Body>
      }
    </Card>
  );
}