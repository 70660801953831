import { NavLink, useLocation } from 'react-router-dom';
import { useSysState } from '../context/SysStateContext';


function UserNavigation(props) {
  const sysState = useSysState().state; // Remember that the state data is within state property!
  const location = useLocation();
  let loginPath, bookingPath;

  if (location.pathname === '/') { // If using "default" path to either login or booking
    loginPath = '/';
    bookingPath = '/';
  } else if (location.pathname.length > 10) { // Initial reactbase/app/build path
    loginPath = location.pathname;
    bookingPath = location.pathname;
  } else {
    loginPath = '/login';
    bookingPath = '/booking';
  }

  if (sysState.auth && sysState.realm === 0) {
    return(
      <ul className="nav nav-pills">
          <li className="nav-item"><NavLink to="/about" className="nav-link" aria-current="page">Om HelleFor</NavLink></li>
          <li className="nav-item"><NavLink to="/login" className="nav-link" aria-current="page">Min side</NavLink></li>
      </ul>);
  }

  return(
      <ul className="nav nav-pills">
        {sysState.auth &&
          <>
          <li className="nav-item">
            <NavLink to={bookingPath} className="nav-link"  id="bookingLink" aria-current="page">Booking</NavLink>
          </li>
          {sysState.lvl >= 80 &&
            <li className="nav-item"><NavLink to="/setup" className="nav-link" aria-current="page">Administration</NavLink></li>
          }
          <li className="nav-item"><NavLink to="/faq" className="nav-link" aria-current="page">Hjælp</NavLink></li>
          <li className="nav-item"><NavLink to="/about" className="nav-link" aria-current="page">Om HelleFor</NavLink></li>
          <li className="nav-item"><NavLink to="/login" className="nav-link" aria-current="page">Min side</NavLink></li>
          </>
        }
        {!sysState.auth &&
          <>
          <li className="nav-item"><NavLink to="/about" className="nav-link" aria-current="page">Om HelleFor</NavLink></li>
          <li className="nav-item"><NavLink to={loginPath} className="nav-link" aria-current="page">Login</NavLink></li>
          </>
        }
      </ul>
  );
}

export default UserNavigation;