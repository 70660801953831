export default class DateHelper {
  date = null;
  static $yearHollidays = {};


  static getFromYearMonth(yearMonth) {
    const d = new Date(parseInt(yearMonth.substring(0,4)),parseInt(yearMonth.substring(5,2))-1,1);
    return new DateHelper(d);
  }

  static getFirstOfCurrentMonth() {
    const d = new Date();
    return new DateHelper(new Date(d.getFullYear(), d.getMonth(), 1));
  }

  constructor(theDate) {
    this.date = theDate;
  }

  toString() {
    return 'My class';
  }

  getNextDates(count, includeThis=true) {
    const a = new Array(count);
    let cur;
    if (includeThis) {
      cur = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    } else {
      cur = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
    }
    for(let i=0; i < count; i++) {
      a[i] = cur;
      cur = new Date(cur.getFullYear(), cur.getMonth(), cur.getDate()+1);
    }
    return a;
  }

  getDatesToMonthShift(includeThis=true) {
    const start = this.date.getDate();
    const count = (new Date(this.date.getFullYear(),this.date.getMonth()+1,0)).getDate()-start+(includeThis?1:0);
    const a = new Array(count);
    let cur;
    if (includeThis) {
      cur = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    } else {
      cur = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
    }
    for(let i=0; i < count; i++) {
      a[i] = cur;
      cur = new Date(cur.getFullYear(), cur.getMonth(), cur.getDate()+1);
    }
    return a;
  }
}