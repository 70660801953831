import { ListGroup, Col, Button } from "react-bootstrap";
import BookableItem from './BookableItem.js';
import { useItem } from '../providers/BookableItemsProvider.js';

export default function BookableItems()  {
  const { selectedItem, setSelectedItem, response, reload } = useItem();

  const newItem = () => {
    setSelectedItem({key: -1, id: -1, name: "", ruleid: 1});
  }

  const itemList = response.payload.map(item => 
    <ListGroup.Item 
      key={item.id} 
      action 
      onClick={() => setSelectedItem({...item, key: item.id})}>
      {item.id !== selectedItem.id ? item.name : <b>{item.name}</b>}
    </ListGroup.Item>);

  return(
    <>
    <Col sm={4} lg={2}>
    <Button variant="outline-primary container" className="mb-1 mt-1" onClick={newItem}>Tilføj en "ting"</Button>
    <ListGroup defaultActiveKey="">
      {itemList}
    </ListGroup>
    </Col>
    <Col sm={8} lg={10}>
     <BookableItem {...selectedItem} topUpdate={reload} itemUpdate={setSelectedItem}  />
    </Col>
    <div></div>
    </>
  );
}